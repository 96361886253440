/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:c5d53974-aa84-4acb-b3c2-6b2fd577466a",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_pQJ1ISTWc",
    "aws_user_pools_web_client_id": "3csbk9jvc047hkuhqr7edvl8m1",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://kioicoazi5h2vi4b7imi4ves74.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "asmapigateway",
            "endpoint": "https://6i7qt18drl.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "asmimage24124-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_content_delivery_bucket": "asm-hosting-dev",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d3ekoa60lqdm0g.cloudfront.net"
};


export default awsmobile;
